<!-- 持续发展 -->
<template>
  <div class="box">
    <!-- 头部 -->
    <headers></headers>
    <el-carousel :interval="3000">
      <el-carousel-item>
        <img src="@/assets/cooperation/develop/zh/绿色制造.png" class="images"
          v-show="bottomBorderIndex == 1">
        <img src="@/assets/cooperation/develop/zh/永续经营.png" class="images"
          v-show="bottomBorderIndex == 2">
        <img src="@/assets/cooperation/develop/zh/员工关怀.png" class="images"
          v-show="bottomBorderIndex == 3">
        <img src="@/assets/cooperation/develop/zh/新技术研发.png" class="images"
          v-show="bottomBorderIndex == 4">
      </el-carousel-item>
    </el-carousel>
    <!-- 中间内容区域 -->
    <div class="content">
      <!-- 头部标题 -->
      <div class="title">
        <ul>
          <li v-for="item in titleList" :key="item.id" @click="titleBtn(item.id)"
            :class="{ bottomBorder: bottomBorderIndex == item.id }">{{ item.name }}</li>
        </ul>
      </div>
      <!-- 文字图片 -->
      <div v-for="item in titleUrl" :key="item.id">
        <img :src="item.url" v-if="item.id == bottomBorderIndex" class="titleUrl">
      </div>
      <!-- 中间内容 -->
      <p v-for="item in tableList" :key="item.id">{{ item.title }}</p>
      <!-- 下载 -->
      <img :src="reportUrl" class="report" @click="downloadPdf">
    </div>
    <!-- 底部 -->
    <foot></foot>
  </div>
  <div class="_div">
    <!-- 头部 -->
    <move_header></move_header>
    <!-- 轮播图 -->
    <van-swipe :autoplay="3000" indicator-color="white">
      <van-swipe-item>
        <img src="@/assets/cooperation/develop/zh/绿色制造.png" class="images"
          v-show="bottomBorderIndex == 1">
        <img src="@/assets/cooperation/develop/zh/永续经营.png" class="images"
          v-show="bottomBorderIndex == 2">
        <img src="@/assets/cooperation/develop/zh/员工关怀.png" class="images"
          v-show="bottomBorderIndex == 3">
        <img src="@/assets/cooperation/develop/zh/新技术研发.png" class="images"
          v-show="bottomBorderIndex == 4">
      </van-swipe-item>
    </van-swipe>
    <!-- 内容区域 -->
    <div class="content">
          <!-- 中间内容头部区域 -->
          <div class="title">
            <ul>
              <li v-for="item in titleListMove" :key="item.id" @click="titleBtn(item.id)"
                :class="{ bottomBorder: bottomBorderIndex == item.id }">{{ item.name }}
              </li>
            </ul>
            <!-- 中间内容文字图片区域 -->
            <div v-for="item in titleUrl" :key="item.id">
             <img :src="item.url" v-if="item.id == bottomBorderIndex" class="titleUrl">
          </div>
          <!-- 文字内容 -->
          <p v-for="item in tableList" :key="item.id">{{ item.title }}</p>
          <!-- 底部图片区域 -->
          <img :src="reportUrl" class="report titleUrl" @click="downloadPdf">
      </div>
    </div>
    <!-- 底部 -->
    <move_foot style="margin-top: -2.3rem;"></move_foot>
  </div>
</template>

<script>
import foot from "../components/foot.vue";
import headers from "../components/header.vue";
import move_foot from "../move_components/move_foot.vue";
import move_header from "../move_components/move_header.vue";
import { useRoute } from "vue-router";
import { reactive, toRefs } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import {downloadPdf} from '@/utils/downloadExportFile.js'
export default {
  components: { foot, headers, move_header, move_foot },

  setup() {
    const route = useRoute();
    const state = reactive({
      
      bottomBorderIndex: 1,
      // 文字内容展示的数据
      tableList: [],
      titleList: [
        { id: 4, name: "共同发展" },
        { id: 3, name: "员工关怀" },
        { id: 2, name: "永续经营" },
        { id: 1, name: "绿色制造" },
      ],
      titleListMove:[
        { id: 1, name: "绿色制造" },
        { id: 2, name: "永续经营" },
        { id: 3, name: "员工关怀" },
        { id: 4, name: "共同发展" },
      ],
      reportUrl: require('@/assets/cooperation/develop/zh/报告下载端.png'),
      titleUrl: [
        { id: 1, url: require('@/assets/cooperation/develop/zh/绿色制造-text.png') },
        { id: 2, url: require('@/assets/cooperation/develop/zh/永续经营-text.png') },
        { id: 3, url: require('@/assets/cooperation/develop/zh/员工关怀-text.png') },
        { id: 4, url: require('@/assets/cooperation/develop/zh/新技术发展-text.png') },
      ],
      // 绿色制造文字内容
      greenWord: [
        { id: 1, title: "我们坚持“做大、走远、做百年光电企业”的愿景作为企业的初心，结合利益相关方，从社会、环境和治理三个方面，搭建和建立公司可持续发展战略体系，科学、合理的决策治理架构，制定可持续发展目标，积极响应国家“3060”目标，我们将通过能源消费低碳化，资源利用循环化、生产过程清洁化、产品供给绿色化、供应物流绿色化、生产方式数字化等方向，执行企业的可持续发展战略" },
        { id: 2, title: "加强管理体系建设，促进治理能力提升。合规合法的管理体系， 对于公司的可持续发展有着极其重要的作用，推动绿色制造体系的建设、安全生产、环境保护、绿色智造，是企业未来实现绿色低碳发展的必由之路。为此，公司通过不断修改、调整、增加文件和制度，使其合法合规，为企业绿色可持续发展助力。 " },
        { id: 3, title: "2022年我们通过了相关体系并获得相关认可。同时为获得ISO27001信息安全管理体系认证，建成信息安全企业，以便进一步获得客户和市场的信任， 提升企业美誉度。" },
        { id: 4, title: "当前，减缓气候变化已成为全球共识。为响应国家“3060”计划， 我们积极通过各种措施，通过设备研发、新材料研发和使用、工艺流程改善、制定能源消耗目标、流程E化等方式，减少碳排放，改善生态环境，实现企业与环境的积极友好发展和共赢，实现企业绿色高质量的可持续发展。2022年我们用电量达 15,201,322(kWh)2023 年，我们将确定和推动节能减碳目标和可再生能源利用占比达到 5%。" },
        { id: 5, title: "同时我们建立了严格的水资源管理体系和水资源小组。2022 共计节约用水量为 18000 吨，相比2021年同等条件下降 12.5%，将提高地表水，雨水以及水资源的重复利用率， 并设定用水目标为同等情况下继续降低10%。" },
        { id: 6, title: "对于废弃物，我们严格按照国家和地方相关法律法规原则进行处理，按照“三化原则”和“统一回收、分类管理、标准处理”的处理原则，建立起废弃物对生产经营过程产生的无害和有害废弃物进行区别处理。无害废弃物，我们进行了重复和回收使用。" },
        { id: 7, title: "对废气的产生，进行集中管理，集中处理，集中排放，并进行监控， 达标排放，通过政府颁发的有资质检测机构检测和监控数据表明，全部达标。" },
      ],
      // 经营文字内容
      manageWord: [
        { id: 1, title: "盛泰光电科技股份有限公司，总部位于中国重庆双桥经开区，是专业从事精密摄像头模组设计、研发、制造和销售的高科技企业。公司未来发展策略依照持续化发展，全球化布局的规划稳步推进；现有重庆、江西分宜、印度德里三个智能制造基地和重庆软件算法公司、深圳新技术研发中心、新加坡公司、海外研发中心等多个生产基地和服务机构。" },
        { id: 2, title: "盛泰光电产品和服务主要聚焦在手机、平板电脑、笔记本电脑、 数码相机、汽车影像、自动驾驶、智能穿戴、医疗、智能家居、智慧城市、安防、无人机、航空航天等领域的高端摄像头产品应用领域； 通过强大的技术支持来服务满足客户和使用者的需求。 盛泰光电未来的发展策略是向影像技术硬件制造和软件服务领域持续不断延伸，努力发展成为一个以科技创新为成长动力的企业。" },
        { id: 3, title: "在万物互联、人机交互的智能互联时代，我们加大研发创新力度， 以全面的可视感知技术来帮助企业、个人、设备实现构筑智能世界的可能。我们以丰富的智能产品，满足不同客户的需求，让他们共享智能互联时代所带来的便利。未来，我们除了现有业务领域以外，也将逐步向智能物联网即服务（AIoTaas）模式出发，通过对智能摄像头的全新解决方案，满足不同行业用户的广泛需求。" },
      ],
      // 员工关怀
      empWord: [
        { id: 1, title: "我们作为一家合法合规经营的企业，深知企业的发展离不开员工的努力，员工的权益保障也离不开企业的“人本”理念。我们依据法律法规、行业标准以及企业自身情况，建立了一系列的制度和文件，保障员工的合法权益。" },
        { id: 2, title: "我们始终坚持“安全第一、预防为主、综合治理、以人为本”的安全生产方针。我们致力于为员工提供一个安全且健康的工作环境。 倡导“安全生产、健康回家”理念。我们建立了健全的安全生产和职业健康管理体系。如我们对《职业健康管理办法》进行长期公示，对年度安全生产知识进行培训，使每个人在生产过程中树立自保互保的三道安全防线意识。" },
        { id: 3, title: "同时，我们深知人才是科技创新的核心驱动力。人才作为企业的第一战略资源， 我们通过外部招聘及内部培养、晋升、培训等多种形式孵化人才。 我们有完善的职业发展通道和人才发展体系，为公司员工提供双向的发展通道，实现自身价值与公司共同成长。" },
        { id: 4, title: "2022年，我们启动了内部讲师培训和认证，全面打造内部讲师队伍，为企业传播企业文化、传承工作经验和知识，提供了有力的通道，作为员工职业发展的领路人，充分做好“传帮带”工作，为企业培养和提供适合企业发展的人才。" },
        { id: 5, title: " “健康工作、快乐生活”一直是我们所倡导的。我们把每个员工当做盛泰大家庭的一份子，时刻关心员工身心健康，举办“公司开放日”“游园亲子活动”“员工生日会”等多姿多彩的活动，丰富员工的日常生活，使大家的生活与工作达到平衡。" },
      ],
      // 研发与发展
      RDWord: [
        { id: 1, title: "我们意识到绿色技术研发和使用是企业未来低碳循环发展的重要方向。我们将投入资金进行户外储能和光伏发电等绿色技术产品使用，并持续加大投入对绿色技术的攻关。同时，我们也通过工艺改善提高部分能源的重复利用率。" },
        { id: 2, title: "在科技创新层面，通过不断努力，我们拥有授权专利452项、计算机软件著作权86项，拥有发明专利109 项，实用新型专利250项，先后获得江西省文化企业20强，江西省级信息化和工业化融合示范企业，江西省级专精特新企业，江西省优强企业50强，江西省级企业技术中心等，并获得市级荣誉“科技型企业”“技术创新中心”“中小企业技术研发中心”，“双百企业”“制造业 100 强”“数字化车间”“重庆市企业技术中心”“两化融合管理体系评定证书”“大足区工业20 强” “新兴产业”“知识产权优势企业”“智能终端企业”等殊荣。" },
        { id: 3, title: "供应链管理作为企业经营的重要环节之一，绿色的供应链是在我们做好自身节能减排和环境保护工作的同时，带动上下游企业持续提高在资源能源方面的利用效率，达到改善环境绩效，实现整个供应链的绿色可持续发展。" },
        { id: 4, title: "我们严格遵守责任商业联盟准则（RBA)，积极引入供应商 ESG评审流程，推进责任采购制度建设。我们在选择供应商时，从劳动标准、健康和安全、环境影响、商业道德这四个维度，全面考察和提升供应商社会责任表现。" },
        { id: 5, title: "盛泰光科作为一家极具社会责任感的企业，深知企业的发展离不开社会的支持。我们积极投身于爱心助学、环境保护等公益事业当中，践行企业社会责任，反哺社会。" },
      ]
    });
    let methods = {
      // 点击标题list
      titleBtn(id) {
        state.bottomBorderIndex = id
        switch (id) {
          case 1:
            state.tableList = state.greenWord
            break;
          case 2:
            state.tableList = state.manageWord
            break;
          case 3:
            state.tableList = state.empWord
            break;
          case 4:
            state.tableList = state.RDWord
            break;

          default:
            break;
        }
      },
      // 点击下载中文报告
      downloadPdf() {

       downloadPdf('https://www.shine-optics.com/video/ows/sustainable_development.pdf','sustainable_development')
    }
  }
    onMounted(() => {
      window.scrollTo(0, 0)
      state.tableList = state.greenWord
    });
    return {
      ...toRefs(state),
      ...methods
    };
  },
};
</script>

<style scoped lang="less">
.el-carousel {
  max-width: 1920px;
  margin: 0 auto;
}

.box {

  width: 100%;

  .bottomBorder {
    border-bottom: 3px solid red;
  }

  .content {
    position: relative;
    max-width: 1920px;
    margin: 0 auto;
    width: 70%;
    height: auto;

    p {
      padding: 0 30px;
      text-indent: 2em;
      Letter-spacing: 1px;
      font-size: 1.25rem;
    }

    .title {
      width: 100%;
      height: 50px;
      margin-top: 20px;

      li {
        float: right;
        cursor: pointer;
        display: inline-block;
        margin-right: 10px;
        font-size: 25px;
      }
    }

    .report {
      width: 100%;
      cursor: pointer;
      margin-top: 30px;
    }

    .titleUrl {
      width: 100%;

    }
  }
}


.images {
  width: 100%;
  height: auto;
  max-height: 600px;
}

::v-deep .el-carousel {
  max-width: 1920px;
  margin: 0 auto;
  overflow-y: hidden;
  height: auto;
  max-height: 600px;
  // max-height: 700px;
}

::v-deep .el-carousel__container {
  height: 100% !important;
  min-height: 500px;
}

.el-carousel {
  max-width: 1920px;
  margin: 0 auto;
}

::v-deep .el-card {
  border-radius: 8px;
}

// 小屏幕区域
@media only screen and (min-width: 767px) {
  .box {
    display: block;
  }

  ._div {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .box {
    display: none;
  }

  ._div {
    display: block;
    p {
      padding: 0 1em;
      text-indent: 3.5em;
      Letter-spacing: 1px;
      font-size: 3.75rem;
      text-align: left;
    }
    .bottomBorder{
      border-bottom: 10px solid red;
    }
    .content {
      position: relative;
      padding: 30px;
      .titleUrl{
        width: 100%;
        margin-top: 30px;
      }
      .report{
        cursor: pointer;
      }
      .title {
      width: 100%;
      margin: 0 0 35px 0;
        text-align: center;
      li {
        cursor: pointer;
        display: inline-block;
        margin-right: 20px;
        font-size: 3.75rem;
        
      }
    }

    }
  }
}</style>